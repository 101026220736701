html {
  width: 100%;
}

body,
h1,
h2,
h3,
h4,
ul,
li,
img,
p,
div {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4 {
  line-height: 1.5 !important;
}

@font-face {
  font-family: archivo;
  src: url(fonts/Archivo-Regular.ttf);
}

.App {
  background-color: white;
}

canvas {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2
}


body {
  font-family: 'Raleway', sans-serif !important;
  /* font-family: 'Dosis', sans-serif !important; */
  /* font-family: "archivo" !important; */
  line-height: 1.4 !important;
  /* background: url("./images/mainBackground.jpeg") no-repeat rgba(0, 0, 0, 0.3);
  background-size: cover; */
  width: "100%";
  height: "100%";
  z-index: 1;
}

.phoneContainer a{
  color: white !important;
}

.phoneContainer a:hover{
  text-decoration: none !important;
}

p{
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.logoStyle {
  font-size: 32px;
  float: left;
  position: relative;
  z-index: 2;
  color: #04c2c9;
  font-weight: bold;
}

.logoStyle:hover {
  text-decoration: none;
  color: #04c2c9;
}

.headerMain {
  width: 85%;
  margin: auto;
  text-align: center;
}

.spanStyle {
  padding: 0px 70px 0px 0px;
}

.spanStyle2 {
  padding: 0px;
}

.linkStyle {
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  border-bottom: none;
  font-weight: normal;
  transition: ease-in-out 0.3s;
}

.linkStyle:hover,
.linkStyle.active {
  text-decoration: none;
  color: #e31b6d;
  /* font-size: 22px; */

}

.linkStyle2 {
  padding: 0px;
}

.headerLinksContainer {
  float: right;
  display: block;
}

.mainTitle {
  font-size: 4.5vw;
  white-space: nowrap
}

.boldStyle {
  text-shadow: 4px 4px #04c2c9;
}

.titles {
  color: black;
  font-weight: bold;
}

.titles{
  color: black;
}

.codeText {
  margin-left: 250px;
  font-size: 18vw;
  color: #04c2c9;
  text-shadow: 4px 4px 2px white;
  font-family: 'Open Sans Condensed', sans-serif !important;
}

.secondTitle {
  color: white;
  font-size: 2vw;
  margin-top: -30px;
}

.contactBtn {
  font-size: 22px;
  color: #04c2c9;
  background-color: transparent;
  padding: 15px;
  box-shadow: none;
  border: 2px solid #04c2c9;
  margin-top: 25px;
  position: relative;
  z-index: 3;
}

.contactBtn:hover {
  background-color: #04c2c9 !important;
  cursor: pointer;
  transition: background-color 0.4s;
  color: white;
}

.innerBtn {
  font-size: 22px;
  color: #04c2c9 !important;
  background-color: transparent !important;
  padding: 10px 20px;
  box-shadow: none;
  border: 2px solid #04c2c9 !important;
  position: relative;
  z-index: 3;
}

.innerBtn:hover {
  background-color: #04c2c9 !important;
  cursor: pointer;
  transition: background-color 0.4s;
  color: white !important;
}


.headerStyle {
  color: #fff;
  padding: 20px 0px 20px 0px;
  display: block;
  width: 100%;
  transition: ease-in-out 0.5s;
}

#gradientContainer {
  width: 100%;
  margin: auto;
  background: rgb(27, 36, 47);
  background: linear-gradient(29deg, rgba(27, 36, 47, 1) 0%, rgba(227, 27, 109, 1) 100%);
  padding-bottom: 180px;
}

.main {
  width: 85%;
  margin: auto;
  padding: 140px 0px 0px 0px;
  position: relative;
}

.mainContainer {
  color: white;
  display: flex;
  transition: color 0.5s;

}

.rubberBand,
.rubberBand2 {
  display: inline-block;
}

.rubberBand:hover,
.rubberBand2:hover {
  animation-name: rubberBandItem;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  cursor: default;
}

.rubberBand:hover {
  color: #0bc8ab;
}

.triggerAnimation {
  animation: rubberBandItem 1s ease-in-out 5s 1 normal;
}

@keyframes rubberBandItem {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.socialHeader {
  color: #fff;
  padding: 40px 0px 0px 0px;
  position: fixed;
  width: 70px;
  margin-top: 250px;
  background-color: rgba(214, 214, 214, 0.5);
  height: 300px;
  margin-left: 20px;
  display: block;
  z-index: 5;
}

.icons {
  width: 70px;
  height: 70px;
}

.icons:hover {
  opacity: 0.5;
  cursor: pointer;
}

.aboutMainContainer {
  display: flex;
  height: 100%;
  width: 85%;
  margin: auto;
  margin-top: 50px;
  flex-wrap: wrap;
  justify-content: space-around;
}

h3.boldWord {
  text-align: center;
}

.octagonContainer {
  width: 85%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.octagonItem {
  text-align: center;
  padding: 10px;
  width: 200px;
}

.octagonItem h4 {
  margin-top: 10px;
}

.octagonItem p {
  line-height: 18pt;
}

.octagonWrap,
.octagonWrapImage, .octagonWrapImage2 {
  width: 130px;
  height: 130px;
  position: relative;
  overflow: hidden;
  margin: auto;
}

.octagonWrapImage, .octagonWrapImage2 {
  transform: rotate(-45deg);
  width: 350px;
  height: 350px;
}

.octagon,
.octagonImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  transform: rotate(45deg);
  background: #04c2c9;

  min-width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.octagonImage {
  background: url('images/mainPic.jpg') no-repeat;
  background-position: center;
  background-size: cover;
  transform: rotate(45deg);
}

.octagon:before {
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  transform: rotate(45deg);
  content: '';
  border: inherit;
}

.aboutIcons,
.aboutIcons2 {
  transform: rotate(-45deg);
  width: 88px !important;
  height: 120px;
  color: white !important;
}

.aboutIcons2 {
  width: 65px !important;
}

.boldWord {
  font-weight: bold;
}

.left {
  width: 40%;
  height: auto;
  min-height: 400px;
  /* background: url('images/mainPic.jpg') no-repeat;
  background-position: center;
  background-size: cover; */
}


.fa-w-12,
.fa-w-14,
.fa-w-16,
.fa-w-20 {
  color: #e31b6d;
}

.right {
  width: 60%;
  height: auto;
}

.bottom {
  width: 100%;
  height: auto;
  padding-bottom: 100px;
}

.aboutTitle {
  color: white;
  text-align: center;
}

.smallLine {
  width: 5%;
  margin: auto;
  text-align: center;
  border-bottom: 4px solid #1b242f;
  margin-bottom: 85px;
}

.paragraph,
.paragraph2 {
  color: black;
  font-size: 22px;
  width: 80%;
  margin: auto;
  margin-top: 20px;
  line-height: 1.8;
}

.paragraph2 {
  width: 50%;
}

.secondBackground {
  background-color: #f5f5f5;
}

.resumeContainer {
  width: 80%;
  margin: auto;
  text-align: center;
  margin-bottom: 30px;
}

.portfolioMainContainer,
.skillsMainContainer {
  display: flex;
  height: 100%;
  width: 85%;
  margin: auto;
  font-size: 26px;
  padding-bottom: 100px;
}

.skillsLogoContainer {
  width: 100%;
  text-align: center;
  margin: auto;
}

.skillsLogo,
.skillsLogo2,
.skillsLogo4 {
  padding: 10px;
}

.skillsLogo {
  width: 160px !important;
}

.skillsLogo2 {
  width: 320px !important;
}

.skillsLogo3 {
  width: 95px !important;
  padding: 5px;
}

.skillsLogo4 {
  width: 38% !important;
  padding: 5px;
}

.innerContainer {
  width: 100%;
}

.portfolioSkillsTitle,
.achievementsTitle,
.aboutTitle {
  color: black;
  padding-top: 85px;
  text-align: center;
}

.portfolioSkillsDescription {
  color: black;
  font-size: 22px;
  width: 80%;
  margin: auto;
  margin-top: 20px;
  flex: flex-wrap;
  text-align: center;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card {
  min-width: 350px !important;
}

.pInnerContainer {
  width: 30%;
  word-break: break-all;
}

.pImage {
  width: 90%;
  height: 60%;
}

.pTitle {
  margin-top: 20px;
  font-weight: bold;
}

.pContent {
  margin-top: 25px;
  font-size: 22px;
  margin-bottom: 25px;
}

.pContainer {
  display: flex;
  justify-content: space-around;
  text-align: center;
  color: white;
  margin-top: 50px;
  flex-wrap: wrap;
  margin: auto;
}

.skillValue {
  color: white;
  word-break: break-all;
  font-size: 22px;
  text-align: center;
}

.column {
  float: left;
  width: 33.33%;
}

.columnContainer{
  display: flex;
  justify-content: center;
}

.column2 {
  width: 100%;
  padding: 10px;
}

.portfolioInner {
  position: relative;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

.portfolioItemsTitle,
.portfolioItemsContent {
  color: white;
  text-align: center;
  display: none;
}

img.portfolioImage {
  height: 275px;
  object-fit: cover;
}

.portfolioItemsTitle {
  margin-top: 15px;
}

.portfolioItemsContent {
  font-size: 17px;
}

.portfolioInner:hover .portfolioItemDescription {
  height: 100%;
}

.portfolioInner:hover .portfolioItemsContent,
.portfolioInner:hover .buttonDisplayA,
.portfolioInner:hover .portfolioItemsTitle
{
  display: block;
}

.control-arrow {
  height: 91% !important;
}

.carouselOverwrite{
  margin-top: 0px !important;
  margin-bottom: 15px !important;
}

.carousel-control-prev, .carousel-control-next {
  background-color: rgba(0, 0, 0, 0.8) !important;
  top: auto !important;
  height: 20% !important;
}


.modalImages{
  width: 100%;
}

.gridTable {
  display: grid;
  grid-template-areas:
    'gridHeader1 gridHeader2 gridHeader3'
    'gridValues1 gridValues2 gridValues3';
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 90%;
  color: white;
}

.gridHeader1 {
  grid-area: gridHeader1;
}

.gridHeader2 {
  grid-area: gridHeader2;
}

.gridHeader3 {
  grid-area: gridHeader3;
}

.gridValues1 {
  grid-area: gridValues1;
}

.gridValues2 {
  grid-area: gridValues2;
}

.gridValues3 {
  grid-area: gridValues3;
}

.gridHeader1,
.gridHeader2,
.gridHeader3 {
  border: 2px solid #0bc8ab;
  padding: 40px 30px 20px 30px;
  text-align: center;
}

.gridValues1,
.gridValues2,
.gridValues3 {
  padding-top: 20px;
}

.carousel {
  height: auto;
  width: 85%;
  margin: auto;
  margin-top: 80px;
  font-size: 22px;
}

.dot {
  background: black !important;
}

img.carousel-cell {
  height: 620px;
}

.carousel.carousel-slider .control-arrow {
  height: 500px;
}

.carousel.carousel-slider {
  margin: auto !important;
}

.carousel .control-dots {
  position: static !important;
}

.carouselItems {
  position: relative;
}

.carouselItemDescription,
.portfolioItemDescription {
  min-width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  bottom: 0;
  text-align: center;
  color: white;
  transition: ease-in-out 0.4s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30%;
}

.portfolioItemDescription {
  height: 0%;
}

.buttonDisplayA {
  display: none;
}

.hiddenAText {
  display: none;
}

.carouselItems:hover .carouselItemDescription {
  height: 100%;
}

.carouselItems:hover .buttonDisplayA,
.carouselItems:hover .hiddenAText {
  display: block;
}

.contactContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  font-size: 22px;
  width: 35%;
  margin: auto;
}

.extraWord{
  margin: 20px 0px 0px 0px;
}

.emailContainer,
.phoneContainer {
  display: -webkit-inline-box;
  align-items: center;
  text-align: center;
  padding: 5px 0px;
}

.contactItems {
  white-space: nowrap;
  color: white;
}
.contactItems:hover {
  text-decoration: none;
  color:white;
}

.contactFooter {
  width: 100%;
  color: white;
  background-color: #252934;
  margin-top: 130px;
  text-align: center;
  padding: 30px 0px 10px 0px;
}

.footer {
  width: 100%;
  padding: 30px 0px 10px 0px;
  color: white;
  background-color: #1b242f;
  text-align: center;
  line-height: 50px;
  font-size: 17px;

}

.footer h2 {
  font-size: 25px;
}

.topnav {
  overflow: hidden;
  background-color: white;
  position: relative;
}

.myLinks {
  display: none;
}

.topnav a {
  color: white;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  display: block;
}


.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.active {
  color: #04c2c9;
}

.navigation {
  position: relative;
}

.item {
  /* float: right; */
  display: none !important;
  z-index: 2;
  /*and place it over the hamburger */
  position: absolute;
  right: 0;
}

#menuToggle {
  display: block;
  position: relative;
  top: 0px;
  right: 0px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a {
  text-decoration: none;
  color: white;
  transition: color 0.3s ease;
}

#menuToggle a:hover {
  color: #03b5d2;
}

#menuToggle input {
  display: none;
}

#menu {
  display: none;
  position: absolute;
  width: 0px;
  margin: 0px 0 0 0px;
  padding: 50px;
  padding-top: 125px;
  right: 0px;
  top: 0px;
  background: #828282;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  z-index: 2;
  transform-origin: 0% 0%;
  transform: translate(200%, 0);
  transition: cubic-bezier(0.77, 0.2, 0.05, 1.0) 0.5s;
}

#menu li {
  padding: 10px 0;
  font-size: 22px;
}

.accordion.accordion {
  width: 90%;
  margin: auto;
}

.accordion-item.card {
  background-color: transparent;
  border-bottom: 1px solid white !important;
}

.accordion a {
  padding: 0;
  color: white;
  font-size: 1.15rem;
  font-weight: 400;
  text-decoration: none;
}

a:not([href]) {
  color: white !important;
}

.btn-link {
  color: white !important;
}

.accordion a:hover,
.accordion a:hover::after {
  cursor: pointer;
  color: #03b5d2 !important;
  text-decoration: none;
}

.btn-link:hover {
  color: #03b5d2 !important;
  text-decoration: none !important;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: none !important;
}

.accordion a:hover::after {
  border: 1px solid #03b5d2;
}


.accordion a::after {
  content: '+';
  position: absolute;
  float: right;
  right: 1.5rem;
  font-size: 1rem;
  color: white;
  padding: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #7288a2;
  text-align: center;
  line-height: -70px;
}

.accordion a.active::after {
  content: '-';
  color: #03b5d2;
  border: 1px solid #03b5d2;
}

.btn-link a.active {
  color: #03b5d2 !important;
}

.accordion {
  display: none;
}

.collapsing,
.show {
  background-color: transparent;
  border-radius: 0px;
  padding-bottom: 0px;
}


@media screen and (max-width : 1500px) {
  .codeText {
    margin-left: 200px;
  }
}

@media screen and (max-width : 1400px) {
  .skillsLogo4 {
    width: 170px !important;
  }
}


@media screen and (max-width : 1280px) {
  #gradientContainer {
    padding-bottom: 325px;
  }
 
}


@media screen and (max-width : 1200px) {

 inTitle {
    font-size: 6.5vw;
  }

  .mainTitle {
    font-size: 5.8vw;
  }

  .codeText {
    display: none;
  }

  .secondTitle {
    margin-top: 25px;
    font-size: 3vw;
  }

  div#responsive-navbar-nav {
    padding-top: 5px;
  }

  .linkStyle {
    font-size: 17px;
  }

  img.portfolioImage {
    min-height: 280px;
  }

  .socialHeader {
    display: none !important;
  }

  .aboutMainContainer {
    display: block;
  }

  .left {
    background-position: top;
    min-height: 42.6vw;
  }

  .left,
  .right {
    width: 100%;
    border: none;
  }

  .paragraph {
    margin-bottom: 30px;
  }

  .column {
    width: 50%;
  }

  .headerLinksContainer {
    display: none;
  }

  #menu {
    display: block;
  }

  img.carousel-cell {
    height: 550px;
  }

  .gridTable {
    display: none;
  }

  .accordion {
    display: block;
  }

  .contactItems {
    font-size: 18px
  }

  .footer h2 {
    font-size: 22px;
  }

  .footer {
    font-size: 14px;
  }
}

@media screen and (max-width : 1080px) {
  .linkStyle {
    font-size: 14px;
  }
}

@media screen and (max-width : 991px) {

  .item {
  display: block !important;
  }

  .spanStyle,
  .spanStyle2 {
    padding: 0px;
  }

  .collapsing,
  .show {
    background-color: #252934;
    opacity: 1;
    border-radius: 15px;
    border: 0px;
    outline: none;
    box-shadow: none;
    text-align: center;
    padding-bottom: 20px;
  }

  .spanStyle,
  .spanStyle2 {
    padding: 7px 0px 7px 0px;
  }

  div#responsive-navbar-nav {
    position: absolute;
    top: 35px;
    width: 100%;
    left: 0;
  }

  .navbar-nav {
    margin-top: 10px;
    margin-left: 0 !important;
  }

  .headerMain {
    padding-bottom: 35px;
  }

  .navbar-light .navbar-toggler {
    float: right;
    margin-top: -14px;
  }

  .navbar-nav {
    margin-top: 10px;
    margin-left: 30px;
  }

  .ml-auto,
  .mx-auto {
    display: flex;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    line-height: 25px;
  }
  .columnContainer{
  flex-wrap: wrap;
  }
  img.portfolioImage {
    min-height: 255px;
  }
}

@media screen and (max-width : 920px) {
  img.portfolioImage {
    min-height: 255px;
  }
  .column {
    width: 100%;
  }
}

@media screen and (max-width : 880px) {
  .mainTitle {
    font-size: 8.5vw;
  }

  .skillsLogo4 {
    width: 150px !important;
  }

  .aboutMainContainer {
    margin-top: 70px;
  }

  .carouselItemDescription {
    padding-top: 10px;
  }
  img.carousel-cell {
    height: 450px;
  }

  .control-arrow {
    height: 90% !important;
  }
}


@media screen and (max-width : 768px) {
  .left {
    min-height: 52.6vw;
  }

  .carouselItemTitle {
    font-size: 3.75vw;
  }

  .carouselItemDescription p,
  .hiddenAText {
    font-size: 3.5vw;
  }

  .paragraph2 {
    width: 70%;
  }
  .contactContainer {
    width: 50%;
  }
}

@media screen and (max-width : 680px) {
  .mainTitle {
    font-size: 9.0vw;
  }

  .secondTitle {
    margin-top: 25px;
    font-size: 6vw;
  }

  .footer h2 {
    font-size: 20px;
  }

  .paragraph,
  .paragraph2 {
    font-size: 3.8vw;
  }

  .skillValue {
    font-size: 18px;
  }

  .skillsLogo2 {
    width: 250px !important;
  }

  img.carousel-cell {
    height: 350px;
  }

  .carousel.carousel-slider .control-arrow {
    height: 350px;
  }

  .carouselItemTitle {
    font-size: 3.5vw;
  }

  .control-arrow {
    height: 87% !important;
  }
  
  .carouselItemDescription p,
  .hiddenAText {
    font-size: 3.3vw;
  }
}

@media screen and (max-width : 500px) {
  .octagonWrapImage{
    width: 52vw;
    height: 52vw;
  }

  .octagonWrapImage2{
    width: 60vw;
    height: 60vw;
  }

  .portfolioMainContainer{
    font-size: 20px;
  }

  .card {
    min-width: 300px !important;
  }

  .smallLine {
    width: 10%;
  }

  .octagonItem {
    width: 170px;
  }

  img.portfolioImage {
    min-height: 210px;
  }

  .control-arrow {
    height: 85% !important;
  }

  .portfolioBtn{
    margin-top: 10px !important;
  }

  .skillsLogo {
    width: 120px !important;
  }

  .skillsLogo2 {
    width: 230px !important;
  }

  .skillsLogo3 {
    width: 75px !important;
  }

  .skillsLogo4 {
    width: 150px !important;
  }

  li.slide, .carousel .slide img, .carousel.carousel-slider .control-arrow {
    height: 300px;
  }

}

@media screen and (max-width : 470px) {
  .contactContainer {
    width: 70%;
  }
}

@media screen and (max-width : 440px) {

  #gradientContainer {
    padding-bottom: 200px;
  }
  .mainTitle {
    margin-top: -35px;
    font-size: 12.0vw;
    white-space: normal;
  }

  .portfolioItemsContent {
    font-size: 15px;
  }

  .aboutMainContainer {
    margin-top: 100px;
  }

  .aboutIcons{
    width: 70px !important;
  }

  .aboutIcons2 {
    width: 55px !important;
  }

  .developerWord {
    white-space: nowrap;
  }


  .titles {
    font-size: 8.5vw;
  }

  .secondTitle {
    font-size: 6vw;
  }

  .carousel.carousel-slider .control-arrow, .carousel .slide img, li.slide {
      height: 200px;
  }

  .contactBtn {
    font-size: 6.0vw;
  }

  .carouselItemDescription{
    height: 45%;
  }

  .control-arrow {
    height: 80% !important;
  }

  li.slide, .carousel .slide img {
    height: 200px;
  }

  .left {
    min-height: 230px;
  }

  .octagonItem {
    width: 130px;
  }

  .octagonWrap, .octagonWrapImage {
    width: 100px;
    height: 100px;
  }

  .octagonItem h4{
    font-size: 0.9rem;
  }

  .octagonItem p {
    font-size: 14px;
  }

  .footer {
    font-size: 12px;
  }
  
}

@media screen and (max-width : 340px) {
  .contactContainer {
    width: 100%;
  }
}

@media screen and (max-width : 320px) {
  .mainTitle {
    font-size: 14.2vw;
  }

  .secondTitle {
    font-size: 8vw;
  }

  .contactItems {
    font-size: 6.5vw;
  }

  .paragraph,
  .paragraph2 {
    font-size: 4.9vw;
  }

  .card {
    min-width: 300px !important;
  }

}

@media screen and (max-width : 300px) {
  .card {
    min-width: 260px !important;
  }
}